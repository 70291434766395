import { IActionContext } from '@msdyn365-commerce/core';
import { GetCategoryPathsInput } from './createGoogleAnalyticsEvent';
import { getCategoryPaths } from '@msdyn365-commerce-modules/retail-actions';

export type GaDataLayer = {
    push: (payload: GaPayload) => void;
};

export type GaItem = {
    item_id: string;
    item_name: string;
    affiliation?: string;
    coupon?: string;
    discount?: number;
    index: number;
    item_brand?: string;
    item_category?: string;
    item_category2?: string;
    item_category3?: string;
    item_category4?: string;
    item_category5?: string;
    item_list_id?: string;
    item_list_name?: string;
    item_variant?: string;
    location_id?: string;
    price: number;
    quantity: number;
    pid?: string;
    recId?: number;
};

export type GaEvent =
    | 'view_item'
    | 'view_cart'
    | 'purchase'
    | 'begin_checkout'
    | 'add_to_cart'
    | 'add_to_wishlist'
    | 'remove_from_cart'
    | 'add_shipping_info'
    | 'add_payment_info';

export type GaPayload = {
    event?: GaEvent;
    ecommerce?: {
        transaction_id?: string;
        currency?: string;
        value: number;
        tax?: number;
        shipping?: number;
        shipping_tier?: string;
        payment_type?: string;
        coupon?: string;
        items?: GaItem[];
    } | null;
};

export const pushGoogleAnalyticsEvent = async (payload?: GaPayload, actionContext?: IActionContext) => {
    const dataLayer = typeof window !== 'undefined' && (window['dataLayer'] as GaDataLayer);
    if (dataLayer) {
        process.env.NODE_ENV === 'development' && console.log('pushGoogleAnalyticsEvent', payload);
        dataLayer.push({ ecommerce: null });
        if (payload) {
            for (const gaItem of payload?.ecommerce?.items ?? []) {
                if (gaItem.recId) {
                    if (actionContext) {
                        const categoryPaths = await getCategoryPaths(
                            new GetCategoryPathsInput(actionContext.requestContext, [{ ProductId: +gaItem.recId }]),
                            actionContext
                        );

                        if (categoryPaths.length > 0) {
                            gaItem.item_category = categoryPaths[0].Name ?? undefined;
                        }
                        if (categoryPaths.length > 1) {
                            gaItem.item_category2 = categoryPaths[1].Name ?? undefined;
                        }
                        if (categoryPaths.length > 2) {
                            gaItem.item_category3 = categoryPaths[2].Name ?? undefined;
                        }
                        if (categoryPaths.length > 3) {
                            gaItem.item_category4 = categoryPaths[3].Name ?? undefined;
                        }
                        if (categoryPaths.length > 4) {
                            gaItem.item_category5 = categoryPaths[4].Name ?? undefined;
                        }
                    }

                    delete gaItem.recId;
                }
            }

            dataLayer.push(payload);
        }
    }
};

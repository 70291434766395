import MsDyn365, { IActionContext } from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';
import { getSelectedVariant, SelectedVariantInput } from '@msdyn365-commerce-modules/retail-actions';
import { createCanonicalUrl } from '../utilities/createCanonicalUrl';

type SailthruCartItem = {
    url: string;
    title: string;
    sku?: string;
    qty: number;
    price?: number;
    images?: {
        full?: { url: string };
        thumb?: { url: string };
    };
};

const handleCartChange = async (ctx: IActionContext) => {
    if (MsDyn365.isBrowser && window.Sailthru) {
        const sailthru = window.Sailthru;
        let userEmail = ctx.requestContext.user.emailAddress;

        // TODO: get user email from Sailthru

        process.env.NODE_ENV === 'development' && console.log('sailthru', 'handleCartChange', userEmail ?? 'no email');

        if (process.env.NODE_ENV === 'development') {
            userEmail = 'elin@bicameral.ca';
        }

        if (userEmail && sailthru) {
            const baseImgUrl = ctx.requestContext.apiSettings.baseImageUrl;
            var channelId = ctx.requestContext.channel?.RecordId;
            const cart = await getCartState(ctx);

            const items: SailthruCartItem[] = [];
            for (const cartLine of cart.cart.CartLines ?? []) {
                const productDetails = await getSelectedVariant(new SelectedVariantInput(cartLine.ProductId!, channelId!), ctx);

                if (productDetails) {
                    const title = productDetails.Name ?? cartLine.Description;
                    let normalizedCanonicalUrl = createCanonicalUrl(ctx, [title ?? ''], `${productDetails.RecordId}.p`).toLowerCase();

                    const dimParams = productDetails.Dimensions?.map(dim => {
                        if (dim.DimensionTypeValue === 1 && dim.DimensionValue?.Value) {
                            return `color=${encodeURIComponent(dim.DimensionValue.Value)}`;
                        }

                        if (dim.DimensionTypeValue === 3 && dim.DimensionValue?.Value) {
                            return `size=${encodeURIComponent(dim.DimensionValue.Value)}`;
                        }

                        if (dim.DimensionTypeValue === 4 && dim.DimensionValue?.Value) {
                            return `style=${encodeURIComponent(dim.DimensionValue.Value)}`;
                        }

                        return null;
                    })
                        .filter(p => !!p)
                        .join('&');

                    if (dimParams) {
                        normalizedCanonicalUrl = `${normalizedCanonicalUrl}?${dimParams}`;
                    }

                    items.push({
                        url: normalizedCanonicalUrl,
                        title: title ?? cartLine.ItemId ?? '',
                        sku: productDetails.ProductNumber ?? cartLine.ItemId,
                        qty: cartLine.Quantity ?? 1,
                        images: {
                            full: {
                                url: `${baseImgUrl}Products%2F${encodeURIComponent(
                                    productDetails.ProductNumber ?? productDetails.ItemId ?? cartLine.ItemId ?? ''
                                )}_000_001.png&fallback=/Products/${encodeURIComponent(
                                    productDetails.ItemId ?? cartLine.ItemId ?? ''
                                )}_000_001.png&q=80&f=jpg`
                            }
                        },
                        price: cartLine.ExtendedPrice ? (cartLine.ExtendedPrice * 100) / (cartLine.Quantity ?? 1) : undefined
                    });
                }
            }

            process.env.NODE_ENV === 'development' && console.log('sailthru', 'items', items);

            sailthru.integration('addToCart', {
                email: userEmail,
                items: items
            });
        }
    }
};

const cartActions = {
    handleCartChange
};

export { cartActions };
